@keyframes fade {
  0%{
    opacity: 0;
  }100%{
    opacity: 1;
  }
}
.login_wrap_logo {
  display: flex; 
  border-radius:10px;
  align-items: center; 
  background: #f9f9f9; 
  flex-wrap: wrap; 
  animation: fade 300ms linear;
  align-content: center;
  box-shadow: 0px 0px 10px rgba(0,0,0,.2);
}
.login_wrap_logo > div {
  padding:25px;
}

.img_logo_auth {
  margin-right: 20px; 
  width: 100%; 
  max-width: 330px;
}

.choose_container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: 100vh;
  background: #f0f0f0;
}
@keyframes fade_box {
  0%{
    opacity: 0;
    transform: translateY(30px);
  }100%{
    opacity: 1;
    transform: translateY(0);
  }
}
.choose_box {
  animation: fade_box 300ms linear;
  padding: 40px 10px; 
  background: #f9f9f9; 
  width: 100%; 
  max-width: 1000px;
  height:100%;
  max-height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  box-shadow: 0 0 5px rgba(0,0,0,.1);
}

.text_embed_preview {
  position: absolute;
  z-index: 10;
  top: 10px;
  left: 10px;
  font-size:16px;
  background: #fff;
  border-bottom:2px solid #054da5;
  box-shadow: 0 0 5px rgba(0,0,0,.4);
  color:#222;
  padding: 5px 15px;
  border-radius: 0px 20px 20px 0px;
}
.button_embed_preview {
  position: absolute; 
  z-index: 10; 
  top: 10px; 
  right: 20px; 
  padding:5px 8.5px;
  border:2px solid #fff;
  color:#fff;
  font-size: 12px; 
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center
}